import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  "aria-hidden": "true",
  viewBox: "-8 -4 800 810"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-miterlimit": "10",
      "stroke-width": "48",
      d: "M731.5 209.7c-3.9 131.9-33.1 250.7-101.1 358-53.1 83.8-123.8 148.8-213.5 192.2-8.2 4-16.9 7.1-24.4 12.2-13.6 9.3-26.2 6.8-40 .4C238.6 720.2 153.6 637 94.7 527.2 64.1 470.1 43.4 409 31.6 345 20.8 286.6 15 228.1 20.7 168.7c.7-7.7 2.9-11.8 10.5-14.8C142.9 110.4 254.3 66.5 366 23.2c5.2-2 12.6-1.6 18 .5 90 34.7 179.8 69.9 269.8 104.9 21.7 8.4 43.6 16.6 65.6 24.3 6.2 2.2 8.8 5.2 9.1 11.7.8 17 2.3 33.9 3 45.1z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m470.3 426.9 77.7 36.8c-22.6 45.2-68.3 94-152.8 94-86.6 0-157-47.8-180.6-122.9h-26.3v-44.6h17.9c-.5-4.7-.5-8.9-.5-13.7 0-5.3 0-10 .5-14.7h-17.9v-44.6h26.8c24.2-74.6 94-121.8 180.1-121.8 84.5 0 129.7 47.8 152.8 94l-77.7 36.8c-12.1-27.8-41.5-50.9-75.1-50.9-34.1 0-62.5 16.3-78.8 42h110.3v44.6H298.6c-.5 4.7-1.1 9.5-1.1 14.7 0 4.7.5 8.9 1.1 13.7h128.1v44.6H315.9c15.8 26.3 44.6 43.1 79.3 43.1 33.6-.1 63-23.2 75.1-51.1"
    }, null, -1)
  ])))
}
export default { render: render }